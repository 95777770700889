import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect, disconnect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import * as s from "../styles/globalStyles";
import styled from "styled-components";
import store from "redux/store";
import Counter from "utils/Counter";
const axios = require('axios');

export const TECH_ISSUE = -2
export const COUNTDOWN = -1
export const INACTIVE = 0
export const PRE_SALES = 1
export const PUBLIC_SALES = 2

const salesRound = Object.freeze({ "Wait": 0, "First": 1, "Second": 2, "Third": 3, "Fourth": 4 });
const salesStatus = Object.freeze({ "Pause": 0, "Sold": 1, "Progress": 2 });
const salesType = Object.freeze({ "General": 0, "Whitelist": 1 });
const salesName = { "0": "Free Mint Not Yet Started", "1": "Free Mint", "2": "1st Public Sale", "3": "2nd Public Sale", "4": "3rd Public Sale" };

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 200px;
  cursor: pointer;
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ResponsiveWrapper = styled.div`
  position: relative;
  justify-content: center;
  width: 100%;
  @media (min-width: 767px) {
    width: 767px;
  }
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

export const ContractLink = styled.a`
  color: white;
  text-decoration: none;
`;


function Mint() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data );

  console.log("data =====> ", data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Update quantity below and click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [mintMessage, setMintMessage] = useState('');

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });


  const getHashMessage = async (userWalletAddress) => {
    const api_host = CONFIG.API_HOST;
    let ok = false;
    let messageHash, signature;

    try {
      const response = await axios.get(api_host + '/sign/' + userWalletAddress)
      const status = response.status
      if ( status === 200 ) {
          messageHash = response.data.messageHash;
          signature = response.data.signature;
          ok = true;
      }
    } catch {
      setFeedback('Failed to connect to ' + api_host)
      console.log("failed connecting " + api_host);
    }
    console.log(ok, messageHash, signature)
    return [ok, messageHash, signature];
  }

  const claimNFTs = async () => {
    // let cost = CONFIG.WEI_COST;
    await Promise.all([dispatch(fetchData(blockchain.account))]);
    let latestData = store.getState().data;
    let cost = latestData.cost;
    let currentSalesRound = latestData.currentSalesRound;
    let currentSalesStatus = latestData.currentSalesStatus;
    let currentSalesType = latestData.currentSalesType;
    // let gasPrice = latestData.gasPrice;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = cost.mul(blockchain.web3.utils.toBN(mintAmount));

    console.log("totalCostWei: " + totalCostWei);
    let totalGasLimit = String(gasLimit * mintAmount);

    let mintNFT;
    let okToMint = true;

    console.log("currentSalesType ====>", currentSalesType, "salesType.Whitelist", salesType.Whitelist);

    if (currentSalesType === salesType.Whitelist) {
      console.log("Verify if wallet address is whitelisted...");
      const [whitelisted, hashMessage, signature] = await getHashMessage(blockchain.account);
      if (whitelisted) {
        mintNFT = blockchain.smartContract.methods.mintNFT(hashMessage, signature, mintAmount);
      } else {
        okToMint = false;
        setFeedback(`Sorry, you are not in the whitelist.`);
        setMintMessage(``);
      }
    } else {
      console.log("Public sales, no need to verify if whitelisted...");
      mintNFT = blockchain.smartContract.methods.mintNFT(mintAmount);
    }

    if (currentSalesRound === salesRound.Wait) {
        setFeedback(`Sorry, Sales has not begun yet.`);
        okToMint = false;
    } else if (currentSalesStatus === salesStatus.Pause) {
        setFeedback(`Sorry, Sales is paused at the moment.`);
        okToMint = false;
    } else if (currentSalesStatus === salesStatus.Sold) {
        setFeedback(`Sorry, Sales for this round is complete.`);
        okToMint = false;
    } else if ( latestData.mintLeft === 0 ) {
        setFeedback(`Sorry, You have exceeded minting limit of Wishful Dolphins.`);
        okToMint = false;
    }

    if ( okToMint ) {
      setFeedback(`Minting your ${CONFIG.NFT_NAME}, please wait...`);
      setMintMessage('You can mint ' + latestData.mintLeft + ' Wishful Dolphin(s).');
      setClaimingNft(true);
      console.log("Before sending transaction!")

      mintNFT.send({
          gasLimit: String(totalGasLimit),
          to: CONFIG.CONTRACT_ADDRESS,
          from: blockchain.account,
          value: totalCostWei,
          gasPrice: null,
          maxPriorityFeePerGas: null,
          maxFeePerGas: null, 
        })
        .on('transactionHash', function(hash){
          console.log('transactionHash:');
          console.log(hash);
        })
        .on('confirmation', function(confirmationNumber, receipt){
          console.log('confirmation:');
          console.log(confirmationNumber);
          console.log(receipt);
        })
        .on('receipt', async function(receipt){
            console.log('receipt:');
            console.log(receipt);
            setFeedback(
              `${CONFIG.NFT_NAME} is yours! Visit Opensea.io to view it.`
            );
            setClaimingNft(false);
            await Promise.all([dispatch(fetchData(blockchain.account))]);
            let latestData = store.getState().data;
            setMintMessage('You can mint ' + latestData.mintLeft + ' Wishful Dolphin(s).');
            setMintAmount(1);
        })
        .on('error', function(error, receipt) { // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
            console.log('I catch which error A ');
            if (error.code === 4001){
              setFeedback("Transaction canceled. You denied the transaction.");
            } else {
              setFeedback("Sorry, something went wrong please check the transaction in your wallet.");
            }
            setClaimingNft(false);
        });
    }

  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let mintLeft = data.mintLeft;
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > mintLeft) {
      newMintAmount = mintLeft;
    }
    if (newMintAmount === 0) {
      newMintAmount = 1;
    }

    console.log("mint mintLeft " + mintLeft);
    console.log("mint newMintAmount " + newMintAmount);
    setMintAmount(newMintAmount);
  };

  const getData = useCallback( async () => {
    console.log("getData called ....");
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      await Promise.all([dispatch(fetchData(blockchain.account))]);
      let latestData = store.getState().data;
      const currentSalesStatus = latestData.currentSalesStatus;
      if (currentSalesStatus === salesStatus.Progress) {
        console.log("Setting Mint Message here!!!!!");
        setMintMessage('You can mint ' + latestData.mintLeft + ' Wishful Dolphin(s).');
      }
    }
  }, [blockchain,dispatch]);

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    console.log("useEffect 1")
    getConfig();
  }, []);

  React.useEffect(() => {
    console.log("useEffect 2")
    console.log("blockchain.account: " + blockchain.account);
    getData()
  }, [getData, blockchain]);

  const walletNotConnected = blockchain.account === null ||  blockchain.account === "" || blockchain.smartContract === null;
  console.log("Mint.jsx walletNotConnected " + walletNotConnected)
  console.log("Mint.jsx blockchain.account " + blockchain.account)
  console.log("Mint.jsx blockchain.smartcontract " + blockchain.smartContract)

  if ( CONFIG.SALES_STATUS === COUNTDOWN ) {
    return (
      <section id="mint">
        <s.Screen>
          <s.Container
            flex={1}
            ai={"center"}
            style={{ padding: 24, backgroundColor: "var(--primary)" }}
          >
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "white",
              fontSize: "26px",
              weight: "weight"
            }}
          >
            300 Free NFT Mints Countdown!<br />March 10 at 1 pm EST
          </s.TextDescription>
          <div className="hero-counter">
            <Counter time="March 10 2024 13:00:00 GMT-0500"/>
          </div>
          (You pay gas fee only)
          </s.Container>
          </s.Screen>
      </section>
    );
  } else {
    return (
      <section id="mint">
        <s.Screen>
          <s.Container
            flex={1}
            ai={"center"}
            style={{ padding: 24, backgroundColor: "var(--primary)" }}
          >
            <ResponsiveWrapper style={{ padding: 24 }} test>
              { CONFIG.SALES_STATUS === TECH_ISSUE ?
                <s.Container
                jc={"center"}
                ai={"center"}
                style={{
                  backgroundColor: "var(--accent)",
                  padding: 24,
                  borderRadius: 16,
                  width: "100%",
                  border: "4px var(--secondary)",
                  boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
                }}
              >
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    color: "white",
                    fontSize: "22px",
                    weight: "bold"
                  }}
                >
                  Sorry, we've encountered some technical difficulties.<br /> 
                  Minting is NOT available right now.<br />
                  Please visit our social channels for latest updates.
                </s.TextDescription>
                <s.SpacerMedium />
                </s.Container>

                : CONFIG.SALES_STATUS === INACTIVE ?
                <s.Container
                jc={"center"}
                ai={"center"}
                style={{
                  backgroundColor: "var(--accent)",
                  padding: 24,
                  borderRadius: 16,
                  width: "100%",
                  border: "4px var(--secondary)",
                  boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
                }}
              >
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    color: "white",
                    fontSize: "22px",
                    weight: "bold"
                  }}
                >
                  Sales has not begun yet.<br /> 
                  Please visit our social channels for latest updates.
                </s.TextDescription>
                <s.SpacerMedium />
                </s.Container>


              : walletNotConnected ? 
                <s.Container
                  jc={"center"}
                  ai={"center"}
                  style={{
                    backgroundColor: "var(--accent)",
                    padding: 24,
                    borderRadius: 16,
                    width: "100%",
                    border: "4px var(--secondary)",
                    boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
                  }}
                >
                  <s.TextDescription
                    style={{
                      textAlign: "center",
                      color: "var(--accent-text)",
                      fontSize: "18px",
                      weight: "bold"
                    }}
                  >
                    Free Mint Live NOW! - Only 300 Available!<br/>Connect to {CONFIG.NETWORK.NAME} network
                  </s.TextDescription>
                  <s.SpacerSmall />
                  {/*<StyledButton
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(connect());
                      // getData();
                    }}
                  >
                    CONNECT WALLET 
                  </StyledButton>*/}
                  <button className="header-join-btn head2-join-btn row" onClick={(e) => {
                      e.preventDefault();
                      dispatch(connect());
                  }}>
                    Connect Wallet
                  </button>
                  {blockchain.errorMsg !== "" ? (
                    <>
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {blockchain.errorMsg}
                      </s.TextDescription>
                    </>
                  ) : null}
                  <s.SpacerMedium />
                </s.Container>
              : 
                <s.Container
                  className={walletNotConnected ? "mint-area-disabled" : ""}
                  jc={"center"}
                  ai={"center"}
                  style={{
                    backgroundColor: "rgba(85, 190, 208, 0.5)",
                    padding: 24,
                    borderRadius: 16,
                    width: "100%",
                    border: "4px var(--secondary)",
                    boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
                  }}
                >
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 24,
                      fontWeight: "bold",
                      color: "var(--accent-text)",
                    }}
                  > 
                    {salesName[String(data.currentSalesRound)]} &nbsp;&nbsp;&nbsp; {data.currentSold} / 300
                  </s.TextTitle>
                  <s.TextSubTitle
                    style={{
                      textAlign: "center",
                      fontSize: 14,
                      margin: "5px 0 0 0",
                      color: "var(--accent-text)",
                    }}
                  > 
                    ( Total Minted &nbsp;&nbsp;&nbsp;{data.totalSupply} / {CONFIG.MAX_SUPPLY} )
                  </s.TextSubTitle>
                  <s.TextDescription
                    style={{
                      textAlign: "center",
                      color: "var(--accent-text)",
                      overflowWrap: "break-word",
                      width: "100%"
                    }}
                  >
                      Connected Wallet: {blockchain.account} <br />
                    <button className="header-join-btn head2-join-btn" onClick={(e) => {
                      e.preventDefault();
                      dispatch(disconnect(blockchain.web3));
                    }}>
                      Disconnect Wallet
                    </button>
                  </s.TextDescription>
                  <s.TextDescription
                    style={{
                      textAlign: "center",
                      color: "var(--primary-text)",
                      overflowWrap: "break-word",
                      width: "100%"
                    }}
                  >
                    <ContractLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                      {CONFIG.CONTRACT_ADDRESS}
                    </ContractLink>
                  </s.TextDescription>
                  {data.totalSupply >= CONFIG.MAX_SUPPLY ? (
                    <>
                      <s.TextTitle
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                      >
                        The sale has ended.
                      </s.TextTitle>
                      <s.TextDescription
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                      >
                        You can still find {CONFIG.NFT_NAME} on
                      </s.TextDescription>
                      <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                        {CONFIG.MARKETPLACE}
                      </StyledLink>
                    </>
                  ) : data.currentSalesRound === salesRound.Wait ? (
                    <>
                      <s.TextTitle
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                      >
                        Sale has not begun yet.<br /><br />
                      </s.TextTitle>
                    </>
                  ) : data.currentSalesStatus === salesStatus.Pause ? (
                    <>
                      <s.TextTitle
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                      >
                        Sale temporarily paused.<br /><br />
                      </s.TextTitle>
                    </>
                  ) : data.currentSalesStatus === salesStatus.Sold ? (
                    <>
                      <s.TextTitle
                        style={{ textAlign: "center", color: "var(--accent-text)", fontSize: "16px" }}
                      >
                       {salesName[String(data.currentSalesRound)]} completed. Please check our Discord for the next round of sale.<br /><br />
                      </s.TextTitle>
                    </>
                  ) : (
                    <>
                      <s.TextTitle
                        style={{ textAlign: "center", color: "var(--accent-text)" }}
                      >
                        {(data.costInEth * mintAmount).toFixed(3)}{" "}
                        {CONFIG.NETWORK.SYMBOL} + gas fee
                      </s.TextTitle>
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {feedback}<br />{mintMessage}
                      </s.TextDescription>
                      { ( mintAmount > 0 ) ? 
                        <>
                          <s.Container ai={"center"} jc={"center"} fd={"row"}>
                            <div className="version-wrapper">
                              <form action="" onSubmit={(e)=> e.preventDefault()}>
                                <button className="qtn-btn minus" disabled={claimingNft ? 1 : 0}onClick={(e)=> {e.preventDefault(); decrementMintAmount();}}>-</button>
                                <input type="number" value={mintAmount} readOnly />
                                <button className="qtn-btn plus" disabled={claimingNft ? 1 : 0}onClick={(e)=> {e.preventDefault(); incrementMintAmount();}}>+</button>
                              </form>
                            </div>
                          </s.Container>
                          <s.Container ai={"center"} jc={"center"} fd={"row"}>
                            <StyledButton
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                claimNFTs();
                                // getData();
                              }}
                            >
                              {claimingNft ? "PROCESSING..." : "BUY"}
                            </StyledButton>
                          </s.Container>
                        </> 
                        : <></>
                      }
                      <s.Container jc={"left"} ai={"left"} style={{ width: "100%" }}>
                        <s.TextDescription
                          style={{
                            textAlign: "left",
                            color: "var(--primary-text)",
                          }}
                        >
                          Please make sure you are connected to the right network (
                          {CONFIG.NETWORK.NAME}) with the correct address. We have 
                          set the gas limit to {CONFIG.GAS_LIMIT} for the contract to
                          successfully mint your NFT. We recommend that you don't lower the
                          gas limit.
                        </s.TextDescription>
                        <s.TextDescription
                          style={{
                            textAlign: "left",
                            color: "var(--primary-text)",
                          }}
                        > Do not mint more than the <strong>limit</strong> specified in FAQ, or your transaction 
                          will not go thru, and you will lose your gas fee.
                          Note that once you make the purchase, you cannot undo this action.
                        </s.TextDescription>
                      </s.Container>
                    </>
                  )}
                </s.Container>
              }
            </ResponsiveWrapper>
          </s.Container>
        </s.Screen>
      </section>
    );

  }

}

export default Mint
