import React from 'react';
import "./footer.css";
import logo from "assets/images/logo.png"

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container ft-top-sec row">
        <div className="infos column">
              <div className="picto"><img src={logo} alt="" /></div>
        </div>
        <div className="right column">
          <div className="social row">
            <div className="link x-link">
              <a
                href="https://x.com/wishfuldolphin"
                target="_blank"
                rel="noopener noreferrer"
                className="x row"
              >.</a>
            </div>
            <div className="link disco-link">
              <a
                href="https://discord.gg/kw2aSSEHfj"
                target="_blank"
                rel="noopener noreferrer"
                className="discord row"
              >.</a>
            </div>
            <div className="link insta-link">
              <a
                href="https://www.instagram.com/wishfuldolphin"
                target="_blank"
                rel="noopener noreferrer"
                className="insta row"
              >.</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
