import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import loop0 from "assets/images/loop/wfd_loop00.png"
import loop1 from "assets/images/loop/wfd_loop01.png"
import loop2 from "assets/images/loop/wfd_loop02.png"
import loop3 from "assets/images/loop/wfd_loop03.png"
import loop4 from "assets/images/loop/wfd_loop04.png"
import loop5 from "assets/images/loop/wfd_loop05.png"
import loop6 from "assets/images/loop/wfd_loop06.png"
import loop7 from "assets/images/loop/wfd_loop07.png"
import loop8 from "assets/images/loop/wfd_loop08.png"
import loop9 from "assets/images/loop/wfd_loop09.png"
SwiperCore.use([Autoplay, Pagination]);

const NftSlider = () => {
    const images = [
        loop0,loop1,loop2,loop3,loop4,loop5,loop6,loop7,loop8,loop9
    ];

    return (
        <section id="nft">
            <Swiper
                autoplay={{ disableOnInteraction: false, delay: 1500 }}
                className="nft-slide-container"
                speed={500}
                initialSlide={3}
                pagination={{ clickable: true }}
                spaceBetween={75}
                centerInsufficientSlides
                slidesPerView={3}
                centeredSlides
                loop={true}
                breakpoints={{
                    190: {
                        slidesPerView: 1,
                        spaceBetween: 15
                    },
                    230: {
                        slidesPerView: 2,
                        spaceBetween: 15
                    },
                    992: {
                        slidesPerView: 3,
                    },
                    1200: {
                        slidesPerView: 4,
                    },
                    1500: {
                        slidesPerView: 4,
                    },
                    1800: {
                        slidesPerView: 5,
                    },
                    2200: {
                        slidesPerView: 5,
                    },
                    2800: {
                        slidesPerView: 6,
                    },
                }}
            >
                {images.map((imgItem, index) => (
                    <SwiperSlide key={index} className="nft-slide">
                        <img className="nft-art-img" src={imgItem} alt="Reindeer" />
                    </SwiperSlide>
                ))}
            </Swiper>
        </section>
    )
}

export default NftSlider
