import React from "react";

const Story = () => {
  return (
    <section id="story">
      <div className="container">
          <div className="" data-aos="fade-up">
            <h2 className="sec-heading">Our Story</h2>
            <p className="story-description">
              <strong>Who:</strong> Dominic the Dolphin
            </p>
            <p className="story-description">
              Welcome aboard! Dominic has been traveling and sightseeing all over the world. He just got back from Paris and is now in Australia visiting family and friends. Dominic is ecstatic to share his experience and stories from his adventures.
            </p>
            <p className="story-description">
              Who is this Dominic, you ask? Dominic is a free spirit, an adventurous and curious dolphin that would try anything once. He loves all cultures and food. He is a great friend to animals and always willing to lend a hand to those in need. He believes that we should take care of the environment and contribute to making it better for future generations. He advocates for clean energy and cleaning up our oceans.
            </p>
            <p className="story-description">
              Dominic might live in the ocean but he loves things that humans use. He’s always trying new things from around the world. His joy in life is dreaming about everyday items and collecting things that people discard in the ocean.
            </p>
            <p className="story-description">
              Dominic is always looking to see more, hear more, and play more.
            </p>
            <p className="story-description"> <strong>Why:</strong> Our team came up with this idea of an idyllic world where Dominic dreams about the world around him with everyday items. To save Dominic and his friends, we pledge to donate 5% of the proceeds to the <a target="_new" href="https://www.dolphinproject.com/">Dolphin Project</a> to help with preserving Dolphins around the world.
            </p>
            <p className="story-description">
              <strong>What:</strong> 10,000 originally designed, unique pixel Wishful Dolphin arts depicting Dominic in gold standard Ethereum ERC-721 NFT tokens.  Mint the tokens now to see Dominic in action!
            </p>
            <p className="story-description">
              <strong>Where:</strong> Around the world.
            </p>
            <p className="story-description">
              <strong>When:</strong> See the Plans Section below for more details.
            </p>
              <span className="desktopdesc">
              </span>
          </div>

      </div>
    </section>
  );
};

export default Story;
