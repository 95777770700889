import React, { useEffect } from 'react';
import "aos/dist/aos.css";
import "./assets/css/style.css";
import "./assets/css/faq.css";
import "./assets/css/roadMap.css";
import "./assets/css/team.css";
import AOS from "aos";
import Home from "./container/Home"
import Header from "./layout/Header/Header"
import Footer from "./layout/Footer/Footer"
import mainLogo from "./assets/images/logo.png"
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
// import Snowfall from 'react-snowfall'


function App() {
  // initialized  Aos and check web load
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 1200,
      offset: 150,
    });
    window.onload = function () {
      document.querySelector('.loader-wrapper').classList.add('d-none')
    };
    console.clear();
  }, [])

  return (
    <>
    {//<div id="snow-canvas">
     // <Snowfall
     //   color="#f3f3f3"
     //   snowflakeCount={18}
     // />
    // </div>
  }
      <Header/>
      <Home />
      <Footer />
      <Loader />
      <GoToTop />
    </>
  );
}


// Website Loader
const Loader = () => {
  return (
    <div className="loader-wrapper">
      <div className="fixed-loading-wrapper">
        <div className="loader-wrapp">
          <div className="loader-logo">
            <img src={mainLogo} alt="Loading..." />
          </div>
          <div className="loader"></div>
        </div>
      </div>
    </div>
  )
};

// Go To Top
const GoToTop = () => {
  useEffect(() => {
    window.onscroll = function () {
      var pageOffset = document.documentElement.scrollTop || document.body.scrollTop;
      let btn = document.getElementById('scrollToTop');
      if (btn) {
        if (pageOffset > 450) {
          btn.classList.add('show-top')
        } else {
          btn.classList.remove('show-top')
        }
      }
    }
  }, []);
  // 
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }
  return (
    <div onClick={goToTop} className="go-to-top" id="scrollToTop">
      <ArrowUpwardIcon className="arrow-up" />
    </div>
  )
}

export default App;
