import React, { useEffect, useState } from "react";

const FullCounter = ({ time }) => {

    const [timeLeft, setTimeLeft] = useState({});
    const times = ["days", "hours", "minutes", "seconds"];
    useEffect(() => {
        const timerInterVal = setInterval(() => {
            const timer = new Date(time || Date.now()).getTime();
            const now = Date.now();

            const remainingTime = timer - now;
            const prevSeconds = Math.floor(remainingTime / 1000);
            const prevMinutes = Math.floor(prevSeconds / 60);
            const seconds = prevSeconds % 60;
            const prevHours = Math.floor(prevMinutes / 60);
            const minutes = prevMinutes % 60;
            const days = Math.floor(prevHours / 24);
            const hours = prevHours % 24;
            if (days >= 0 && hours >= 0 && minutes >= 0 && seconds >= 0){
                setTimeLeft({ seconds, minutes, hours, days });
            }
        }, 1000)
        return ()=> {
            clearInterval(timerInterVal);
          };
    }, [time]);
    return (
        <div className="counter">
            {times.map((el, i) => (
                <Counter key={i}
                    time={el}
                    timeLog={
                        timeLeft[el] > 9
                            ? timeLeft[el]
                            : timeLeft[el]
                                ? "0" + timeLeft[el]
                                : "00"
                    }
                />
            ))}
        </div>
    );
};


const Counter = ({ time, timeLog = 0 }) => {
    return (
        <div className="counter-item">
            <h5 className="counter--label">{time} </h5>
            <div className="counter--value">
                <h5>{timeLog}</h5>
            </div>
        </div>
    );
};

export default FullCounter;
