import React from 'react';
import placeholder2 from "../assets/images/arts/dolphin_sample4.png";
import placeholder3 from "../assets/images/arts/dolphin_sample1.png";
import placeholder5 from "../assets/images/arts/dolphin_sample3.png";
import placeholder6 from "../assets/images/arts/dolphin_sample2.png";
import dolphinImg from "../assets/images/others/dolphin.png";

const RoadMAP = () => {
    const dataArray = [
        {
            title: "Wishful Dolphin Creation",
            period: 'Winter 2023 - Completed',
            bodyText:
                <>
                    <p>
                        Wishful Dolphin art designs and creation, NFT Contract and DApp (this site).
                    </p>
                </>
        },
        {
            title: "Wishful Dolphin NFT Sale",
            period: 'Spring 2024 - Exact date and time TBD',
            bodyText:
                <>
                    <p>
                        The 10,000 Wishful Dolphin NFTs will be released to the public in Spring 2024 with exact date and time TBD.
                    </p>
                    <p>
                        We will be doing marketing campaigns on our social media channels, please check them for latest news and promotions.
                    </p>
                </>
        },
        {
            title: "Wishful Dolphin Journey",
            period: 'From Time to Time',
            bodyText:
                <ul>
                    <li>We will publish articles on <a target="_new" href="https://medium.com/@wishfuldolphin">medium.com/@wishfuldolphin</a> about tips and challenges we faced in our project.</li>
                    <li>We believe these content guides and resources will be helpful to others who are working on similar projects.</li>
                </ul>
        },
        {
            title: "Donation",
            period: 'Upon completion of NFT sale',
            bodyText:
                <>
                    <p>
                        5% of the proceeds will be donated to <a target="_new" href="https://www.dolphinproject.com/">Dolphin Project</a>, an established non-profit organization dedicated to the welfare 
                        and protection of dolphins worldwide. 
                    </p>
                    <p>
                        We are thrilled to make a contribution to the dolphin community.  
                    </p>
                </>
}
    ];
    return (
        <section id="roadmap" className="roadmap-main">
            <div className="container">
                <div className="sec-heading-wrapper">
                    <h1 data-aos="fade-down" className="sec-heading">Plans</h1>
                </div>
                <div className="rd-timeline">
                    <div className="timeline-left">
                        {dataArray.map((data, index) => {
                            return (
                                <div key={index} className="container-map">
                                    <div className="content" data-aos="fade-up">
                                        <h3 className="roadmap-section-heading">{data.title}</h3>
                                        <span className="date-about-roadmap">{data.period}</span>
                                        {data.bodyText}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="timeline-right">
                        <img loading="lazy" data-aos="fade-down" src={placeholder2} alt="Wishful Dolphin" />
                        <img loading="lazy" data-aos="fade-up" src={placeholder3} alt="Wishful Dolphin" />
                        <img loading="lazy" data-aos="fade-down" src={placeholder5} alt="Wishful Dolphin" />
                        <img loading="lazy" data-aos="fade-up" src={placeholder6} alt="Wishful Dolphin" />
                    </div>
                </div>
            </div>
            <img className="big_dolphin" src={dolphinImg} alt="" />
        </section>
    )
};

export default RoadMAP
