import React from 'react';
import member1 from "assets/images/arts/dolphin_sample1.png"
import member2 from "../assets/images/arts/dolphin_sample2.png"
const OurTeam = () => {
    const images = [
        { name: 'Benja-phin', role: 'Art Designer and Developer', img: member1 },
        { name: 'Caro-phin',role: 'Community Manager', img: member2 },
    ]
    return (
        <section id="team">
            <div className="sec-heading-wrapper">
                <h1 className="sec-heading" data-aos="fade-down">Our Team</h1>
            </div>
            <div className="team-wrapper row">
                {images.map((data, ind) => {
                    return (
                        <div key={ind} className="row column team-card">
                            <img src={data.img} alt={data.name} />
                            <div className="member-card-body row column">
                                <h2>{data.name}</h2>
                                <p className="role-">{data.role}</p>
                            </div>
                        </div>
                    )
                })}
            </div>
        </section>
    )
}

export default OurTeam

