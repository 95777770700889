import { useState } from 'react';
import React from 'react'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const faqData = [
    {
        id: 0,
        qs: 'What is Wishful Dolphin NFT, and how do they work?',
        ans: "NFTs, an acronym for Non-Fungible Tokens, are one-of-a-kind tokens that represent a unique digital good or digital assets such as digital art.  Most NFTs are part of the Ethereum blockchain. Wishful Dolphin (WFD) NFT are a collection of 10,000 originally designed, and unique pixel arts depicting Dominic. The arts are designed with over 100 traits and randomly generated and stored in gold standard Ethereum ERC-721 tokens."
    },
    {
        id: 1,
        qs: 'How do I get my hands on a Wishful Dolphin ? ',
        ans: "Only on our website (https://wishfuldolphin.io) will you be able to initially mint a Wishful Dolphin NFT. Minted NFTs will be available on secondary marketplace such as Opensea.io."
    },
    {
        id: 2,
        qs: 'What wallet can I use to mint Wishful Dolphin NFTs?',
        ans: 'We recommend using MetaMask and mint on Ethereum Mainnet.'
    },
    {
        id: 3,
        qs: 'What is the price of a mint?',
        ans: 'We are currently hosting a Free NFT Mints Event on March 10, you pay 0 ETH (plus gas fee). Only 300 of them are avaliable, please visit our social channels for latest updates.'
    },
    {
        id: 4,
        qs: 'How many WFD NFTs can I mint?',
        ans: 'There is a maximum of 10 mints per wallet address.'
    },
    {
        id: 5,
        qs: 'When will the reveal take place?',
        ans: "Each minted Wishful Dolphin is revealed instantly, the easiest way to view them is on https://opensea.io/collection/wishfuldolphin "
    },
    {
        id: 6,
        qs: 'Is it a legitimate mint?',
        ans: `Absolutely, we believe in fair mints. We will offer multiple rounds of sales, so everyone can get a chance to mint.
        In addition, 3% of the Wishful Dolphins will be reserved for marketing and promotions to get the word out to the public. 
        We'll be rewarding a very small number of our most dedicated and early community members with these promotions. `
    },
    {
        id: 7,
        qs: 'Are there any royalties on secondary sales?',
        ans: 'For the initial mint of NFTs, there are no royalties.  However, there will be a 3.5% royalty on secondary marketplace, Opensea.'
    },
    {
        id: 8,
        qs: 'Are the arts found on this website part of the 10k collection?',
        ans: 'These are early designs that we created for demonstration purposes. They may or may not be part of the collection, but the collection will look very similar to the design.'
    }
]

const Faq = () => {
    const [activeFaq, setActiveFaq] = useState(null);
    return (
        <section id="faq">
            <div className="container">
                    <div className="sec-heading-wrapper">
                        <h1 className="sec-heading" data-aos="fade-down">FAQs</h1>
                    </div>
                    <div>
                        {faqData.map((data, i) => {
                            return (
                                <div 
                                 key={i} className={`faq-wrapper ${activeFaq === i ? 'active-faq' : ''}`}>
                                    <div data-aos="fade-up" className="faq-qs" onClick={() => setActiveFaq(prev => prev === i ? null : i)}>
                                        <h6>{data.qs}</h6><span className="faq-arrow"><ArrowForwardIosIcon /></span>
                                    </div>
                                    <div className="faqs-answer text-white">
                                        <p>
                                            {data.ans}
                                        </p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
            </div>
        </section>
    )
}

export default Faq