import React from 'react';
import "./header.css";
import { useState } from "react";
//import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/images/logo.png";
import styled from "styled-components";
//import { connect } from "../../redux/blockchain/blockchainActions";
import { Menu, Close, ChevronRight, } from '@material-ui/icons';


export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 200px;
  cursor: pointer;
`;


const Header = () => {
    // const dispatch = useDispatch();
    const [sideBar, setSideBar] = useState(false);
    // go to the section
    const scrollTo_Section = (sec_id) => {
        let element = document.getElementById(sec_id);
        var yOffset = -60;
        if (sec_id === 'home') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
            if (element) {
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({ top: y, behavior: 'smooth' });
            }
        }
    };

    return (
        <>
            <header className="web-header">
                <div className="row header-container">
                    <div className="header-logo-area row">
                        <span onClick={() => scrollTo_Section('home')} className="row">
                            <img className="header-main-logo" loading="lazy" src={logo} alt="" />
                        </span>
                    </div>
                    <div className="header-right-area">
                        {/*<button className="header-join-btn head2-join-btn row">
                            Connect Wallet
                        </button>*/}
                        <div className="menu-btn">
                            {sideBar ?
                                <Close onClick={() => setSideBar(false)} className="menu-icon" />
                                : <Menu onClick={() => setSideBar(true)} className="menu-icon" />
                            }
                        </div>
                    </div>
              
                {sideBar && <div onClick={() => setSideBar(false)} className="backdrop-sidebar"></div>}
                <nav className={`side-bar-wrapper ${sideBar ? 'opened-sidebar' : ''}`}>
                    <div className="side-logo">
                        <img loading="lazy" src={logo} alt="" />
                    </div>
                    <ul>
                        <li><button className="nav-items" onClick={() => { setSideBar(false); scrollTo_Section('story'); }}>Our Story</button></li>
                        <li><button className="nav-items" onClick={() => { setSideBar(false); scrollTo_Section('nft') }}>Collection</button></li>
                        <li><button className="nav-items" onClick={() => { setSideBar(false); scrollTo_Section('roadmap') }}>Plans</button></li>
                        <li><button className="nav-items" onClick={() => { setSideBar(false); scrollTo_Section('faq') }}>FAQ</button></li>
                        <li><button className="nav-items" onClick={() => { setSideBar(false); scrollTo_Section('team') }}>Team</button></li>
                        <li>
                            {/*<button className="header-join-btn side-join-btn row">
                                Connect Wallet
                                </button>*/}
                        </li>
                    </ul>
                    <div className="social sidebar-socials row">
                        <div className="link x-link">
                            <a href="https://x.com/wishfuldolphin" target="_blank" rel="noopener noreferrer" className="x row">.</a>
                        </div>
                        <div className="link disco-link">
                            <a href="https://discord.gg/kw2aSSEHfj" target="_blank" rel="noopener noreferrer" className="discord row">.</a>
                        </div>
                        <div className="link insta-link">
                            <a href="https://www.instagram.com/wishfuldolphin" target="_blank" rel="noopener noreferrer" className="insta row">.</a>
                        </div>
                    </div>
                    <div className="sidebar-close-btn" onClick={() => setSideBar(false)}>
                        <ChevronRight className="chev-icon" />
                    </div>
                </nav>
                </div>
            </header>
            <div className="header-spacer"></div>
        </>
    )
}

export default Header
