// constants
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import Web3Modal from "web3modal";
import MetaMaskLogo from "../../assets/logos/metamask.svg";
import WalletConnectLogo from "../../assets/logos/walletconnect-circle.svg";
import { fetchData } from "../data/dataActions";


const providerOptions = {
  injected: {
    display: {
      logo: MetaMaskLogo,
      name: "MetaMask",
      description: "Connect with the provider in your Browser"
    },
    package: null
  },
  // Example with WalletConnect provider
  walletconnect: {
    display: {
      logo: WalletConnectLogo,
      name: "WalletConnect",
      description: "Scan qrcode with your mobile wallet"
    },
    package: WalletConnectProvider,
    options: {
      infuraId: "e81eecb93f5e45f4b1cb594a1b1baa2a" 
    }
  }
}

const web3Modal = new Web3Modal({
  network: "mainnet",  
  cacheProvider: false,   
  disableInjectedProvider: false,
  providerOptions
});

const resetApp = async (web3) => {
  if (web3 && web3.currentProvider && web3.currentProvider.close) {
    await web3.currentProvider.close();
  }
  await web3Modal.clearCachedProvider();
};

const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};

const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};

const updateAccountRequest = (payload) => {
  return {
    type: "UPDATE_ACCOUNT",
    payload: payload,
  };
};

const connectionReset = (payload) => {
  return {
    type: "CONNECTION_RESET",
    payload: payload,
  };
};

export const disconnect = (web3) => {
  console.log("disconnect function here...");
  return async (dispatch) => {
      resetApp(web3);
      dispatch(connectionReset({}));
  };
};

export const connect = () => {
  return async (dispatch) => {
    dispatch(connectRequest());
    const abiResponse = await fetch("/config/abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const abi = await abiResponse.json();
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const CONFIG = await configResponse.json();
//    const { ethereum } = window;
//    const metamaskIsInstalled = ethereum && ethereum.isMetaMask;
//    console.log("metamaskIsInstalled: " + metamaskIsInstalled);

    const metamaskIsInstalled = true;

    if (metamaskIsInstalled) {

      try {
        const provider = await web3Modal.connect();
        const web3 = new Web3(provider);

        const Web3EthContract = require('web3-eth-contract');
        Web3EthContract.setProvider(provider);
        const accounts = await web3.eth.getAccounts();
        const networkId = await web3.eth.getChainId();

        // eslint-disable-next-line
        if (networkId == CONFIG.NETWORK.ID ) {
          const SmartContractObj = new Web3EthContract(
            abi,
            CONFIG.CONTRACT_ADDRESS
          );
          dispatch(
            connectSuccess({
              account: accounts[0],
              smartContract: SmartContractObj,
              web3: web3,
              web3Modal: web3Modal,
              provider: provider
            })
          );

          // Add listeners start
          provider.on("close", () => {
              console.log("provider close");
              dispatch(disconnect(web3));
          });

          // Subscribe to accounts change
          provider.on("accountsChanged", (code: number) => {
            const accountSwitch = code[0];
            if ( accountSwitch ) {
              console.log("account changed event");
              console.log("account changed: " + accountSwitch);
              dispatch(updateAccount(accountSwitch));
            } else {
              console.log("reset app");
              resetApp(web3);
              dispatch(disconnect(web3));
            }
          });

          // Subscribe to chainId change
          provider.on("chainChanged", (chainId: number) => {
            console.log("chain changed event");
            console.log(chainId);
            window.location.reload();
          });

/*
          // Subscribe to provider connection
          provider.on("connect", (info: { chainId: number }) => {
            console.log("connect event");
            console.log(info);
          });

          provider.on("disconnect", (error: { code: number; message: string }) => {
            console.log("disconnect event");
          });
*/
          // await provider.enable();

          // Add listeners end
        } else {
          dispatch(connectFailed(`Change network to ${CONFIG.NETWORK.NAME}.`));
        }
      } catch (err) {
        console.log("provider error caught");
        dispatch(connectFailed("Error: " + err.message ));
      }
    } else {
      dispatch(connectFailed("Please Install Metamask."));
    }
  };
};

export const updateAccount = (account) => {
  return async (dispatch) => {
    dispatch(updateAccountRequest({ account: account }));
    dispatch(fetchData());
  };
};


