const initialState = {
  loading: false,
  totalSupply: 0,
  cost: 0,
  costInEth: 0,
  currentSold: null,
  currentMaxSupply: null,
  maxAllowedMints: 0,
  maxWhiteListMints: 0,
  currentSalesRound: null,
  currentSalesStatus: null,
  currentSalesType: null,
  addressMinted: 0,
  mintLeft: 0,
  error: false,
  errorMsg: "",
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        totalSupply: action.payload.totalSupply,
        cost: action.payload.cost,
        costInEth: action.payload.costInEth,
        currentSold: action.payload.currentSold,
        currentMaxSupply: action.payload.currentMaxSupply,
        maxAllowedMints: action.payload.maxAllowedMints,
        maxWhiteListMints: action.payload.maxWhiteListMints,
        currentSalesRound: action.payload.currentSalesRound,
        currentSalesStatus: action.payload.currentSalesStatus,
        currentSalesType: action.payload.currentSalesType,
        addressMinted: action.payload.addressMinted,
        mintLeft: action.payload.mintLeft,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;