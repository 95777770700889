// log
import store from "../store";
import Web3 from "web3";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

const getMintLeft = (currentSold,currentMaxSupply,maxAllowedMints,maxWhiteListMints,
    currentSalesType,currentSalesStatus,addressMinted,addressWLMinted) => {

  let supplyLeft = currentMaxSupply - currentSold;
  let salesType = currentSalesType;
  let salesStatus = currentSalesStatus;

  console.log("salesStatus = " + salesStatus)

  if (salesStatus < 2) {
    return 0;
  }

  console.log("addressMinted = " + addressMinted);
  console.log("addressWLMinted = " + addressWLMinted);

  // address constraint
  let addressLimit = maxAllowedMints - addressMinted;
  let addressWLLimit = maxWhiteListMints - addressWLMinted;

  console.log("addressLimit = " + addressLimit);
  console.log("addressWLLimit = " + addressWLLimit);

  if ( salesType === 1 && addressWLLimit < addressLimit ) {
    addressLimit = addressWLLimit;
  }

  console.log("getMintLeft: addressLimit = " + addressLimit);
  console.log("getMintLeft: supplyLeft = " + supplyLeft);

  if (addressLimit <= 0) {
    return 0; 
  }

  // Supply Constraint
  if ( supplyLeft === 0 ) {
    return 0;
  }

  if ( supplyLeft < addressLimit ) {
    return supplyLeft;
  } else {
    return addressLimit;
  }
}

export const fetchData = (addr) => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let contractStatus = await store.getState()
        .blockchain.smartContract.methods.contractStatus()
        .call();

      let totalSupply = parseInt(contractStatus[0]);
      let cost = contractStatus[1];
      let maxAllowedMints = parseInt(contractStatus[2]);
      let maxWhiteListMints = parseInt(contractStatus[3]);
      let roundMaxSupply = contractStatus[4];
      let currentSalesRound = parseInt(contractStatus[5]);
      let currentSalesStatus = parseInt(contractStatus[6]);
      let currentSalesType = parseInt(contractStatus[7]);
      let currentMaxSupply = roundMaxSupply[currentSalesRound];
      let currentSold = totalSupply;
      for (let i=0; i<currentSalesRound; i++) {
        currentSold -= roundMaxSupply[i];
      }
      if (currentSold > currentMaxSupply) {
        currentSold = currentMaxSupply;
      }
      if (currentSalesRound === 0) {
        currentMaxSupply = roundMaxSupply[1];
      }

      let addressMinted = 0;
      let addressWLMinted = 0;
      addressMinted = await store.getState()
        .blockchain.smartContract.methods.totalMints(addr)
        .call();
      addressMinted = parseInt(addressMinted);
      if ( currentSalesType === 1) {
        addressWLMinted = await store.getState()
          .blockchain.smartContract.methods.whiteListMints(currentSalesRound,addr)
          .call();
        addressWLMinted = parseInt(addressWLMinted);
      }
      let mintLeft = getMintLeft(currentSold,currentMaxSupply,maxAllowedMints,maxWhiteListMints,
        currentSalesType,currentSalesStatus,addressMinted,addressWLMinted);
      cost = Web3.utils.toBN(cost);

      // ensure it is wei
      // cost = Web3.utils.BN(cost);
      // cost = String(parseInt(cost) + parseInt(cost * (Math.floor(Math.random() * 10) + 1) /50));
      let costInEth = Web3.utils.fromWei(cost, 'ether');
      // let gasPrice = await store
      //   .getState()
      //   .blockchain.web3.eth.getGasPrice();

      dispatch(
        fetchDataSuccess({
          totalSupply,
          cost,
          costInEth,
          currentSold,
          currentMaxSupply,
          maxAllowedMints,
          maxWhiteListMints,
          currentSalesRound,
          currentSalesStatus,
          currentSalesType,
          addressMinted,
          addressWLMinted,
          mintLeft
        })
      );
      console.log("======= fetchData =========>");
      console.log("Fetching contract data....");
      console.log("totalSupply: " + totalSupply);
      console.log("cost:        " + cost);
      console.log("costInEth:   " + costInEth);
      console.log("currentSold:        " + currentSold);
      console.log("currentMaxSupply:   " + currentMaxSupply);
      console.log("maxAllowedMints:    " + maxAllowedMints);
      console.log("maxWhiteListMints:  " + maxWhiteListMints);
      console.log("currentSalesRound:  " + currentSalesRound);
      console.log("currentSalesStatus: " + currentSalesStatus);
      console.log("currentSalesType:   " + currentSalesType);
      console.log("addressMinted:      " + addressMinted);
      console.log("mintLeft:           " + mintLeft);
      console.log("=========================>");
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
