import React from 'react';
import Story from 'components/Story'
import Mint from 'components/Mint'
import NftSlider from 'components/NftSlider'
import RoadMap from 'components/RoadMap'
import FAQ from 'components/Faq'
import Team from 'components/OurTeam'
import store from "../redux/store";
import { Provider } from "react-redux";

const Home = () => {
    return (
        <main>
            <Story />
            <Provider store={store}>
                <Mint />
            </Provider>
            <NftSlider />
            <RoadMap />
            <FAQ />
            <Team />
        </main>
    )
}

export default Home
